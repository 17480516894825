import axios from '@/plugins/axiosV2'

const http = axios(process.env.VUE_APP_API_FORMALIZACION+'/v2');

export default {
    enviar_solicitud(payload){
        return http.post(`/cofinanciamiento/solicitudes/enviar`, payload);
    },

    obtener_solicitudes(id){
        return http.get(`/cofinanciamiento/solicitudes/${id}`);
    },
    
    actualizar_estatus(id, payload){
        return http.put(`/cofinanciamiento/solicitudes/${id}`, payload);
    },
    
    cancelar_solicitud(id){
        return http.delete(`/cofinanciamiento/solicitudes/${id}`);
    }
}